import { useState } from "react";
import MotionHoc from "./MotionHoc";
import { Helmet } from "react-helmet";
import "./Whyus.scss";
import { useTranslation } from "react-i18next";

const WhyusComponent = () => {
  const [activeItem, setActiveItem] = useState("join-us");
  const { t } = useTranslation();
  const handleClick = (itemId) => {
    setActiveItem(itemId);
  };

  const isItemActive = (itemId) => {
    return itemId === activeItem;
  };

  return (
    <>
      <Helmet>
        <title>Why us?</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/Whyus" />
      </Helmet>
      <div className="whaiuz">
        <h1 className="why-us-heading" id="whyus-description">
          {t("whyUsHeading")}
        </h1>
        <div className="why-us-items">
          <div className="why-us-item">
            <h2
              className={`why-us-item-title ${
                isItemActive("join-us") ? "actived" : ""
              }`}
              onClick={() => handleClick("join-us")}
              id="join-us"
            >
              {t("joinUsTitle")}
            </h2>
            <p
              className={`why-us-item-description ${
                isItemActive("join-us") ? "fade-in" : ""
              }`}
              id="join-us-description"
              style={{ display: isItemActive("join-us") ? "block" : "none" }}
            >
              {t("joinUsDescription")}
            </p>
          </div>
          <div className="why-us-item">
            <h2
              className={`why-us-item-title ${
                isItemActive("dedicated-support") ? "actived" : ""
              }`}
              onClick={() => handleClick("dedicated-support")}
              id="dedicated-support"
            >
              {t("dedicatedSupportTitle")}
            </h2>
            <p
              className={`why-us-item-description ${
                isItemActive("dedicated-support") ? "fade-in" : ""
              }`}
              id="dedicated-support-description"
              style={{
                display: isItemActive("dedicated-support") ? "block" : "none",
              }}
            >
              {t("dedicatedSupportDescription")}
            </p>
          </div>
          <div className="why-us-item">
            <h2
              className={`why-us-item-title ${
                isItemActive("continuous-improvement") ? "actived" : ""
              }`}
              onClick={() => handleClick("continuous-improvement")}
              id="continuous-improvement"
            >
              {t("continuousImprovementTitle")}
            </h2>
            <p
              className={`why-us-item-description ${
                isItemActive("continuous-improvement") ? "fade-in" : ""
              }`}
              id="continuous-improvement-description"
              style={{
                display: isItemActive("continuous-improvement")
                  ? "block"
                  : "none",
              }}
            >
              {t("continuousImprovementDescription")}
            </p>
          </div>
          <div className="why-us-item">
            <h2
              className={`why-us-item-title ${
                isItemActive("transparency-accountability") ? "actived" : ""
              }`}
              onClick={() => handleClick("transparency-accountability")}
              id="transparency-accountability"
            >
              {t("transparencyAccountabilityTitle")}
            </h2>
            <p
              className={`why-us-item-description ${
                isItemActive("transparency-accountability") ? "fade-in" : ""
              }`}
              id="transparency-accountability-description"
              style={{
                display: isItemActive("transparency-accountability")
                  ? "block"
                  : "none",
              }}
            >
              {t("transparencyAccountabilityDescription")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Whyus = MotionHoc(WhyusComponent);

export default Whyus;
