import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import MotionHoc from "./MotionHoc";
import "./ContactUs.scss";
import { Helmet } from "react-helmet";
import addy from "../assets/addy.webp";
import phone from "../assets/phonage.webp";
import watsapp from "../assets/wotsup.webp";
import { useTranslation } from "react-i18next";

const ContactUsForm = () => {
  const { t, i18n } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t("Required");
    }
    if (!values.email) {
      errors.email = t("Required");
    }
    if (!values.message) {
      errors.message = t("Required");
    }
    return errors;
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!executeRecaptcha) {
      console.log("Recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("contact_form");

    const formPayload = {
      ...values,
      "g-recaptcha-response": token,
    };

    fetch("/scripts/process-contact.php", {
      method: "POST",
      body: JSON.stringify(formPayload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(t("formSubmitted"));
          resetForm();
        } else if (data.error) {
          toast.error(t("formSubmissionError") + data.error);
        } else {
          toast.error(t("unexpectedResponse"));
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error(t("formSubmissionError") + error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{ name: "", email: "", subject: "", message: "" }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id="contact-form">
          <Field
            type="text"
            name="name"
            placeholder={t("contact_name")}
            autoComplete="name"
            className="form-control"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <ErrorMessage
            name="name"
            component="div"
            className="error-message"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <Field
            type="email"
            name="email"
            placeholder={t("contact_email")}
            autoComplete="email"
            className="form-control"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <ErrorMessage
            name="email"
            component="div"
            className="error-message"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <Field
            type="text"
            name="subject"
            placeholder={t("contact_subject")}
            className="form-control"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <Field
            as="textarea"
            name="message"
            placeholder={t("contact_message")}
            className="form-control"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <ErrorMessage
            name="message"
            component="div"
            className="error-message"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <button type="submit" className="btn" disabled={isSubmitting}>
            {t("submitbutton")}
          </button>
        </Form>
      )}
    </Formik>
  );
};

const ContactUsComponent = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="contactz">
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Contact us for any questions or inquiries"
        />
        <link rel="canonical" href="https://siliconsquire.com/ContactUs" />
      </Helmet>
      <GoogleReCaptchaProvider reCaptchaKey="6LeRsHgoAAAAAKknldMIr4BA9RH24ABz6rPIm9eG">
        <div id="contact-section">
          <div id="contact-text" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <h2 id="contact-us-h2">{t("contactUs")}</h2>
            <h1 id="question-h3">{t("haveQuestion")}</h1>
            <p id="contact-description">{t("contactDescription")}</p>
          </div>
          <ContactUsForm />
        </div>
        <h1 id="contact-contact">{t("contact")}</h1>
        <div className="contact-info">
          <div className="contact-item">
            <img src={addy} alt="Address" />
            <p id="address-p1">{t("address")}</p>
          </div>
          <div className="contact-item">
            <img src={phone} alt="Phone" />
            <p id="phone-p">+9647766964850</p>
          </div>
          <div className="contact-item">
            <a
              href="https://wa.me/message/NSGEG4K5EQWIM1"
              target="_blank"
              rel="noopener noreferrer"
              id="whatsapp"
            >
              <img src={watsapp} alt="Whatsapp" />
            </a>

            <p id="whatsapp-p">+13147621147</p>
          </div>
        </div>
      </GoogleReCaptchaProvider>
      <ToastContainer />
    </div>
  );
};

const ContactUs = MotionHoc(ContactUsComponent);

export default ContactUs;
