import MotionHoc from "./MotionHoc";
import "./Home.scss";
import { Helmet } from "react-helmet";
import MainLogo from "../assets/Logcenter.webp";
import { useTranslation } from "react-i18next";

const HomeComponent = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="MainContainer" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <Helmet>
        <title>Web and SEO Professionals</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com" />
        <link rel="preload" href={MainLogo} as="image" />
      </Helmet>
      <div className="home-waviy">
        <img
          src={MainLogo}
          alt="Silicon Squire Logo"
          width={200}
          height={200}
        />
      </div>
      <h1 id="header-title">{t("headerTitle")}</h1>
      <div id="home">
        <p id="home-message-1">
          <span className="highlight">{t("homeMessage1.highlight")}</span>{" "}
          <span className="fade-text first">{t("homeMessage1.text")}</span>
        </p>
        <p id="home-message-2">
          <span className="highlight">{t("homeMessage2.highlight")}</span>{" "}
          <span className="fade-text second">{t("homeMessage2.text")}</span>
        </p>
        <p id="home-message-3">
          <span className="highlight">{t("homeMessage3.highlight")}</span>{" "}
          <span className="fade-text third">{t("homeMessage3.text")}</span>
        </p>
        <p id="home-message-4">
          <span className="highlight">{t("homeMessage4.highlight")}</span>{" "}
          <span className="fade-text fourth">{t("homeMessage4.text")}</span>
        </p>
      </div>
      <h2 id="home-h2">{t("homeH2")}</h2>
    </div>
  );
};

const Home = MotionHoc(HomeComponent);

export default Home;
