import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en";

i18n.use(initReactI18next).init({
  resources: {
    en,
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const loadLanguage = async (lng) => {
  const translations = await import(`./locale/${lng}.js`);
  i18n.addResourceBundle(lng, "translation", translations.default.translation);
};
export default i18n;
