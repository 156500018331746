import MotionHoc from "./MotionHoc";
import { Helmet } from "react-helmet";

const NotFoundComponent = () => {
  return (
    <div
      className="not-found"
      id="not-found"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "auto",
        textAlign: "center",
      }}
    >
      <Helmet>
        <title>404 Not Found</title>
        <meta
          name="description"
          content="The page you are looking for does not exist."
        />
        <link rel="canonical" href="https://siliconsquire.com/NotFound" />
      </Helmet>
      <h2 id="not-found-h2">404: Page Not Found</h2>
      <p id="not-found-content">The page you are looking for does not exist.</p>
    </div>
  );
};

const NotFound = MotionHoc(NotFoundComponent);

export default NotFound;
