import MotionHoc from "./MotionHoc";
import "./Technologies.scss";
import { Helmet } from "react-helmet";
import reactLogo from "../assets/react.webp";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const TechnologiesComponent = () => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(null); // No tab is active initially

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="technologies" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <Helmet>
        <title>Technologies Used</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/Technologies" />
      </Helmet>

      {/* Conditionally render the heading and paragraph */}
      {!activeTab && (
        <div className="tech-intro">
          <h1 className="tech-introh1">{t("technologiesHeading")}</h1>
          <p className="tech-introp">{t("technologiesIntroduction")}</p>
        </div>
      )}

      {/* Tabs Navigation */}
      <div className="tabs">
        <button onClick={() => handleTabClick("frontend")}>
          {t("frontendTab")}
        </button>
        <button onClick={() => handleTabClick("backend")}>
          {t("backendTab")}
        </button>
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {activeTab === "frontend" && (
          <div className="frontend">
            <h2 className="advantages-heading">{t("advantagesHeading")}</h2>
            <img
              className="react"
              loading="lazy"
              src={reactLogo}
              alt="react logo"
            />
            <div className="advantage-content">
              <p className="react-description">
                <strong
                  className="react-description-strong"
                  style={{ color: "#FFD700" }}
                >
                  {t("reactStrong")}
                </strong>{" "}
                {t("reactDescription")}
              </p>

              <p className="scss-description">
                <strong
                  className="scss-description-strong"
                  style={{ color: "#FFD700" }}
                >
                  {t("scssStrong")}
                </strong>{" "}
                {t("scssDescription")}
              </p>

              <p className="combined-benefits-description">
                <strong
                  className="combined-benefits-description-strong"
                  style={{ color: "#FFD700" }}
                >
                  {t("combinedBenefitsStrong")}
                </strong>{" "}
                {t("combinedBenefitsDescription")}
              </p>
            </div>
          </div>
        )}

        {activeTab === "backend" && (
          <div className="backend">
            <h2 className="advantages-heading">{t("backendTitle")}</h2>

            <div className="advantage-content">
              <p>{t("backendDescription")}</p>
              <div className="advantage-content">
                <h3>{t("backendContent.0.title")}</h3>
                <p>{t("backendContent.0.description")}</p>
              </div>
              <div className="advantage-content">
                <h3>{t("backendContent.1.title")}</h3>
                <p>{t("backendContent.1.description")}</p>
              </div>
              <div className="advantage-content">
                <h3>{t("backendContent.2.title")}</h3>
                <p>{t("backendContent.2.description")}</p>
              </div>
              <div className="advantage-content">
                <h3>{t("backendContent.3.title")}</h3>
                <p>{t("backendContent.3.description")}</p>
              </div>
              <div className="advantage-content">
                <h3>{t("backendContent.4.title")}</h3>
                <p>{t("backendContent.4.description")}</p>
              </div>
              <div className="advantage-content">
                <h3>{t("backendContent.5.title")}</h3>
                <p>{t("backendContent.5.description")}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Technologies = MotionHoc(TechnologiesComponent);

export default Technologies;
