import MotionHoc from "./MotionHoc";
import "./AboutUs.scss";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  FaGlobeAmericas,
  FaTools,
  FaUserTie,
  FaHandshake,
} from "react-icons/fa";

const AboutUsComponent = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="about-us"
      id="about-us"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <Helmet>
        <title>About us</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/AboutUs" />
      </Helmet>

      <div className="about-section">
        <h3>
          <FaGlobeAmericas /> {t("ourMission")}
        </h3>
        <p>{t("missionDescription")}</p>
      </div>

      <div className="about-section">
        <h3>
          <FaTools /> {t("ourServices")}
        </h3>
        <p>{t("aboutservicesDescription2")}</p>
      </div>

      <div className="about-section">
        <h3>
          <FaUserTie /> {t("ourFounder")}
        </h3>
        <p>{t("founderDescription")}</p>
      </div>

      <div className="about-section">
        <h3>
          <FaHandshake /> {t("joinUsAbout")}
        </h3>
        <p>{t("joinUsDescriptionAbout")}</p>
      </div>
    </div>
  );
};

const AboutUs = MotionHoc(AboutUsComponent);

export default AboutUs;
