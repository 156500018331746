import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.scss";
import Sidebar from "./components/Sidebar/Sidebar";
// import logo from "./assets/Logo.png";
import Footer from "./components/Footer/Footer";
import "./i18n";
import Whatsapp from "./assets/wotsup.webp";
import Background1 from "./assets/midi4.webp";
import BackgroundMobile from "./assets/BG2-Mobile.webp";

// Pages
import Home from "./Pages/Home";
import Technologies from "./Pages/Technologies";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import ClientsSamples from "./Pages/ClientsExample";
import Philosophy from "./Pages/Philosophy";
import ContactUs from "./Pages/ContactUs";
import Pricing from "./Pages/Pricing";
//import Downloads from "./Pages/Downloads";
import Whyus from "./Pages/Whyus";
//import Roadmap from "./Pages/Roadmap";
import Analyze from "./Pages/Analyze";
import { useTranslation } from "react-i18next";
import NotFound from "./Pages/notfound";

function App() {
  const { t, i18n } = useTranslation();
  const [showWidget, setShowWidget] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 768) {
      window.particlesJS.load(
        "particles-js",
        "/particles.json",
        function () {}
      );
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowWidget(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  //const detectScale = () => {
  //const scaleFactor = window.devicePixelRatio;
  // const root = document.documentElement;
  //root.classList.remove("scale-100", "scale-125", "scale-150");
  // if (scaleFactor > 1.24 && scaleFactor < 1.26) {
  //  root.classList.add("scale-125");
  //} else if (scaleFactor > 1.49 && scaleFactor < 1.51) {
  //   root.classList.add("scale-150");
  //} else {
  //   root.classList.add("scale-100");
  // }
  //};

  //useEffect(() => {
  // detectScale();
  //  window.addEventListener("resize", detectScale);
  // return () => window.removeEventListener("resize", detectScale);
  // }, []);

  return (
    <>
      <div className={i18n.dir() === "rtl" ? "rtl" : ""}>
        <div id="particles-js" className="particles-container"></div>{" "}
        {/* Particle Container */}
        <div className="App">
          <img src={Background1} alt="Background" className="background" />
          <img
            src={BackgroundMobile}
            alt="Background"
            className="backgroundMobile"
          />
          <Sidebar />
          <div className="contentWrapper">
            <AnimatePresence mode="wait">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Technologies" element={<Technologies />} />
                <Route path="/ContactUs" element={<ContactUs />} />
                <Route path="/AboutUs" element={<AboutUs />} />
                <Route path="/ClientsSamples" element={<ClientsSamples />} />
                <Route path="/Services" element={<Services />} />
                <Route path="/Analyze" element={<Analyze />} />
                <Route path="/Philosophy" element={<Philosophy />} />
                <Route path="/Pricing" element={<Pricing />} />
                <Route path="/Whyus" element={<Whyus />} />
                {/* <Route path="/Downloads" element={<Downloads />} />
                <Route path="/Roadmap" element={<Roadmap />} />*/}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AnimatePresence>
          </div>

          {showWidget && (
            <div
              id="whatsapp-floating-widget"
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <a
                href="https://wa.me/message/NSGEG4K5EQWIM1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Whatsapp}
                  alt={t("whatsappAlt")}
                  style={i18n.language === "ar" ? { float: "left" } : {}}
                />
                <div className="widget-text" id="whatsapp-widget">
                  {t("chatWithExpert")}
                  <br />
                  {t("noCharges")}
                </div>
              </a>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
