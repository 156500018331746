import MotionHoc from "./MotionHoc";
import "./ClientsExample.scss";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Demo1 from "../assets/Demo1.webp";
import Demo2 from "../assets/Demo2.webp";
import BabilAgency from "../assets/BabilAgency.webp";
import Blogz from "../assets/blogz.webp";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const ClientsSamplesComponent = () => {
  const { t } = useTranslation();
  const clientSamples = [
    {
      thumbnail: Demo1,
      siteName: "Demo 1",
      description: t("demo1Description"),
      link: "https://demo1.siliconsquire.com",
    },
    {
      thumbnail: Demo2,
      siteName: "Demo 2",
      description: t("demo2Description"),
      link: "https://demo2.siliconsquire.com",
    },
    {
      thumbnail: BabilAgency,
      siteName: "Client: Babil Agency",
      description: t("babilAgencyDescription"),
      link: "https://babilagency.com",
    },
    {
      thumbnail: Blogz,
      siteName: "Our Blog",
      description: t("blogDescription"),
      link: "https://blogs.siliconsquire.com",
    },
  ];

  return (
    <div className="ClientsExample">
      <Helmet>
        <title>Clients And Samples</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/ClientsSamples" />
      </Helmet>
      <Carousel className="client-carousel">
        {clientSamples.map((sample, index) => (
          <div key={index} className="carousel-item">
            <img
              src={sample.thumbnail}
              alt={sample.siteName}
              className="carousel-image"
            />
            <p className="legend carousel-legend">{sample.siteName}</p>
            <p className="carousel-description">{sample.description}</p>
            <a
              href={sample.link}
              target="_blank"
              rel="noopener noreferrer"
              className="carousel-link"
            >
              {t("viewlive")}
            </a>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const ClientsSamples = MotionHoc(ClientsSamplesComponent);

export default ClientsSamples;
