import { useState } from "react";
import { Helmet } from "react-helmet";
import "./Services.scss";
import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaSearch,
  FaCodeBranch,
  FaPaintBrush,
  FaLaptopCode,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import SeoBig from "../assets/seo-big.webp";
import GraphicDesignBig from "../assets/graphicdesign-big.webp";
import ContentMarketingBig from "../assets/contentmarketing-big.webp";
import WebDesignBig from "../assets/webdesign-big.webp";

const ServicesComponent = () => {
  const [activeContent, setActiveContent] = useState(
    "service-webdesign-desktop"
  );
  const { t, i18n } = useTranslation();

  const serviceCards = [
    {
      id: "service-webdesign-desktop",
      icon: <FaLaptopCode size={40} />,
      title: t("webDesignTitle"),
      image: WebDesignBig,
      heading: t("webDesignHeading"),
      content: t("webDesignContent"),
      subHeading: t("webDesignSubHeading"),
      listItems: [
        t("webDesignListItem1"),
        t("webDesignListItem5"),
        t("webDesignListItem2"),
        t("webDesignListItem3"),
        t("webDesignListItem4"),
        <Link
          key="checkOutOurWork"
          to="/ClientsSamples"
          className="special-link"
        >
          {t("checkOutOurWork")}
        </Link>,
      ],
    },
    {
      id: "service-seo-desktop",
      icon: <FaSearch size={40} />,
      title: t("seoTitle"),
      image: SeoBig,
      heading: t("seoHeading"),
      content: t("seoContent"),
      listItems: [
        t("seoListItem1"),
        t("seoListItem2"),
        t("seoListItem3"),
        t("seoListItem4"),
        t("seoListItem5"),
      ],
    },
    {
      id: "service-graphicdesign-desktop",
      icon: <FaPaintBrush size={40} />,
      title: t("graphicDesignTitle"),
      image: GraphicDesignBig,
      heading: t("graphicDesignHeading"),
      content: t("graphicDesignContent"),
      listItems: [
        t("graphicDesignListItem1"),
        t("graphicDesignListItem2"),
        t("graphicDesignListItem3"),
      ],
    },
    {
      id: "service-additional",
      icon: <FaCodeBranch size={40} />,
      title: t("additionalServicesTitle"),
      image: ContentMarketingBig,
      heading: t("webMaintenanceHeading"),
      content: t("webMaintenanceContent"),
      listItems: [
        t("webMaintenanceListItem4"),
        t("webMaintenanceListItem5"),
        t("webMaintenanceListItem1"),
        t("webMaintenanceListItem2"),
        t("webMaintenanceListItem3"),
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/Services" />
      </Helmet>
      <div
        className="desktop-view"
        id="desktop-view-container"
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <Row className="service-cards" id="desktop-service-icons">
          {serviceCards.map((service) => (
            <Col
              key={service.id}
              md={4}
              onClick={() => setActiveContent(service.id)}
            >
              <Card
                className={`service-card ${
                  activeContent === service.id ? "active" : ""
                }`}
              >
                <Card.Body>
                  <div className="icon-container">{service.icon}</div>
                  <Card.Title className="service-title">
                    {service.title}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <AnimatePresence>
          {serviceCards.map(
            (service) =>
              activeContent === service.id && (
                <motion.div
                  key={service.id}
                  className="service-content"
                  id={service.id}
                  initial={{ opacity: 0, rotateY: 90, scale: 0.8 }}
                  animate={{
                    opacity: 1,
                    rotateY: 0,
                    scale: 1,
                    transition: {
                      type: "spring",
                      stiffness: 100,
                      damping: 10,
                      duration: 0.5,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    rotateY: -90,
                    scale: 0.8,
                    transition: {
                      type: "spring",
                      stiffness: 100,
                      damping: 10,
                      duration: 0.5,
                    },
                  }}
                >
                  <img
                    loading="lazy"
                    src={service.image}
                    alt={`${service.title} service info`}
                    className="big-image"
                  />
                  <div className="content-container">
                    <h3>{service.heading}</h3>
                    <p dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                      {service.content}
                    </p>
                    <ul>
                      {service.listItems.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ServicesComponent;
